import React from "react";
import WaitingForPermission from "./WaitingForPermission";
import AVSettings from "./AVSettings";
import ReviewSelfie from "./ReviewSelfie";
import PreviewSelfie from "./PreviewSelfie";

export const WAITING_FOR_PERMISSION = "waiting-for-permission";
export const PERMISSION_DENIED = "permission-denied";
export const AV_SETTINGS = "av-settings";
export const PREVIEWING_SELFIE = "previewing-selfie";
export const REVIEW_SELFIE = "review-selfie";

const PreviewAVScreen = ({
  state,
  videoDeviceOptions,
  videoDeviceId,
  setVideoDeviceId,
  audioInputDeviceOptions,
  audioInputDeviceId,
  setAudioInputDeviceId,
  video,
  audioLevel,
  onAVSettingsComplete,
  selfieCanvas,
  onTakeSelfie,
  selfieSrc,
  onBackToSettings,
  countingDown,
  onCountdownFinished,
  onRetakeSelfie,
  onSaveSelfie,
}) => {
  switch (state) {
    case WAITING_FOR_PERMISSION:
      return <WaitingForPermission />;

    case AV_SETTINGS:
      return (
        <AVSettings
          videoDeviceOptions={videoDeviceOptions}
          videoDeviceId={videoDeviceId}
          setVideoDeviceId={setVideoDeviceId}
          audioInputDeviceOptions={audioInputDeviceOptions}
          audioInputDeviceId={audioInputDeviceId}
          setAudioInputDeviceId={setAudioInputDeviceId}
          video={video}
          audioLevel={audioLevel}
          onAVSettingsComplete={onAVSettingsComplete}
        />
      );

    case PREVIEWING_SELFIE:
      return (
        <PreviewSelfie
          video={video}
          selfieCanvas={selfieCanvas}
          onTakeSelfie={onTakeSelfie}
          countingDown={countingDown}
          onCountdownFinished={onCountdownFinished}
          onCancel={onBackToSettings}
        />
      );

    case REVIEW_SELFIE:
      return (
        <ReviewSelfie
          src={selfieSrc}
          onSaveSelfie={onSaveSelfie}
          onRetakeSelfie={onRetakeSelfie}
        />
      );

    default:
      return null;
  }
};

export default PreviewAVScreen;
