import React, { useContext, useEffect, useRef, useState } from "react";
import FormField from "../FormField";
import Label from "../Label";
import Select from "../Select";
import VolumeLevel from "../VolumeLevel";
import PrimaryButton from "../buttons/PrimaryButton";
import PermissionDeniedHelp, {
  isAVPermissionDenied,
} from "../PermissionDeniedHelp";
import DeviceContext from "../../contexts/DeviceContext";
import { useThrottle } from "@react-hook/throttle";
import inDevelopment from "../../util/inDevelopment";
import sleep from "../../util/sleep";
import { navigate } from "gatsby";
import { defaultAudioOptions, defaultVideoOptions } from "../../twilioOptions";
import { createLocalAudioTrack, createLocalVideoTrack } from "twilio-video";
import pollAudioLevel from "../../util/pollAudioLevel";
import Bugsnag from "@bugsnag/js";
import getLogger from "../../util/getLogger";
import styled from "styled-components";
import DarkPageHeading from "../DarkPageHeading";

const logger = getLogger("PreviewAV");

const Preview = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 0;
  ${({ show }) =>
    show &&
    `
    opacity: 1
  `}
`;

const SelectWrapper = styled.div`
  display: flex;
  & > * {
    margin-left: 5px;
    margin-right: 5px;
  }
`;

const PreviewAudioVideoWrapper = styled.div`
  display: flex;
`;

const PreviewAudioWrapper = styled.div`
  margin: 0 10px;
`;

const PreviewVideoWrapper = styled.div`
  width: 480px;
  height: 300px;
`;

const PreviewVideo = styled.video`
  object-fit: cover;
  height: 100%;
  width: 100%;
  border-radius: 20px;
  transform: scale(-1, 1);
`;

const SelfieCanvas = styled.canvas`
  display: none;
`;

const ButtonWrapper = styled.div`
  margin: 40px 0 80px;
`;

const PreviewAV = ({ next, selfie, onSelfieTaken }) => {
  const { enumerateDevices } = useContext(DeviceContext);
  const [nextClicked, setNextClicked] = useState(false);
  const [takeSelfieClicked, setTakeSelfieClicked] = useState(false);
  const [audioPermissionDenied, setAudioPermissionDenied] = useState(false);
  const [videoPermissionDenied, setVideoPermissionDenied] = useState(false);
  const [audioStreamInitialized, setAudioStreamInitialized] = useState(false);
  const [videoStreamInitialized, setVideoStreamInitialized] = useState(false);
  const [audioLevel, setAudioLevel] = useThrottle(0, 5);

  const videoRef = useRef();

  const selfieCanvasRef = useRef(null);

  const handleNextClicked = async () => {
    setNextClicked(true);
    if (inDevelopment()) {
      await sleep(2000);
    }

    if (next === "event") {
      await navigate("/event/");
    } else {
      await navigate("/play-quiz/");
    }
  };

  const handleTakeSelfieClicked = async () => {
    setTakeSelfieClicked(true);

    const selfieCanvasEl = selfieCanvasRef.current;
    const videoEl = videoRef.current;

    selfieCanvasEl.width = 300;
    selfieCanvasEl.height = 300;
    const context = selfieCanvasEl.getContext("2d");
    context.drawImage(videoEl, -45, 0, 390, 300);

    const selfieData = selfieCanvasEl.toDataURL("image/png");

    onSelfieTaken(selfieData);

    setTakeSelfieClicked(false);
  };

  const {
    audioDevices,
    audioDeviceId,
    setAudioDeviceId,
    videoDevices,
    videoDeviceId,
    setVideoDeviceId,
  } = useContext(DeviceContext);

  const audioOptions = audioDevices.map((device) => ({
    value: device.deviceId,
    label: device.label,
  }));

  const videoOptions = videoDevices.map((device) => ({
    value: device.deviceId,
    label: device.label,
  }));

  const selectedAudioOption = audioOptions.find(
    (option) => option.value === audioDeviceId
  );

  const selectedVideoOption = videoOptions.find(
    (option) => option.value === videoDeviceId
  );

  const handleAudioChange = (option) => setAudioDeviceId(option.value);
  const handleVideoChange = (option) => setVideoDeviceId(option.value);

  useEffect(() => {
    let audioTrack = null;

    async function testMic() {
      try {
        let constraints = {
          ...defaultAudioOptions,
        };
        if (audioDeviceId) {
          constraints = {
            deviceId: { exact: audioDeviceId },
          };
        }
        const newAudioTrack = await createLocalAudioTrack(constraints);
        enumerateDevices();
        audioTrack = newAudioTrack;

        setAudioStreamInitialized(true);

        await pollAudioLevel(newAudioTrack, (level) => {
          setAudioLevel(level);
        });
        logger.info("Created local audio track");
      } catch (error) {
        Bugsnag.notify(error);

        if (isAVPermissionDenied(error)) {
          logger.warn("Permission to access mic was denied");
          setAudioPermissionDenied(true);
        } else {
          logger.error(error);
        }
      }
    }

    testMic();

    return () => {
      if (audioTrack) {
        audioTrack.stop();
        logger.info("stopped audio track");
      } else {
        logger.info("no audio track to stop");
      }
    };
  }, [audioDeviceId]);

  useEffect(() => {
    let videoTrack = null;

    async function testCamera() {
      try {
        let constraints = {
          ...defaultVideoOptions,
        };
        if (videoDeviceId) {
          constraints = {
            deviceId: { exact: videoDeviceId },
          };
        }

        logger.debug("createLocalVideoTrack constraints", constraints);

        const newVideoTrack = await createLocalVideoTrack(constraints);
        enumerateDevices();
        videoTrack = newVideoTrack;

        logger.debug("Got new video track");
        logger.debug(newVideoTrack);

        logger.debug("About to attach to videoRef.current");
        logger.debug(videoRef.current);

        newVideoTrack.attach(videoRef.current);
        setVideoStreamInitialized(true);

        logger.info("Attached video track");
      } catch (error) {
        Bugsnag.notify(error);

        if (isAVPermissionDenied(error)) {
          logger.warn("Permission to access video was denied");
          setVideoPermissionDenied(true);
        } else {
          logger.error(error);
        }
      }
    }

    testCamera();

    return () => {
      if (videoTrack) {
        videoTrack.stop();
        logger.info("stopped video track");
      } else {
        logger.info("no video track to stop");
      }
    };
  }, [videoDeviceId]);

  const permissionDenied = audioPermissionDenied || videoPermissionDenied;
  const mediaStreamsInitialized =
    audioStreamInitialized && videoStreamInitialized;

  return (
    <>
      <DarkPageHeading>Preview Video and Audio</DarkPageHeading>
      {!permissionDenied && (
        <>
          {!mediaStreamsInitialized && (
            <div>Waiting for permission to access camera and microphone.</div>
          )}
          <Preview show={mediaStreamsInitialized}>
            <SelectWrapper>
              <FormField>
                <Label>Choose your video source</Label>
                <Select
                  options={videoOptions}
                  value={selectedVideoOption}
                  onChange={handleVideoChange}
                />
              </FormField>
              <FormField>
                <Label>Choose your audio source</Label>
                <Select
                  options={audioOptions}
                  value={selectedAudioOption}
                  onChange={handleAudioChange}
                />
              </FormField>
            </SelectWrapper>
            <PreviewAudioVideoWrapper>
              <PreviewVideoWrapper>
                <PreviewVideo ref={videoRef} autoPlay={true} />
              </PreviewVideoWrapper>
              <PreviewAudioWrapper>
                <VolumeLevel level={audioLevel} />
              </PreviewAudioWrapper>
            </PreviewAudioVideoWrapper>
            <SelfieCanvas ref={selfieCanvasRef} />
            {selfie ? (
              <ButtonWrapper>
                <PrimaryButton
                  size="large"
                  disabled={takeSelfieClicked}
                  onClick={handleTakeSelfieClicked}
                  data-cy="take-selfie-button"
                >
                  {takeSelfieClicked ? "Taking Selfie" : "Take a Selfie"}
                </PrimaryButton>
              </ButtonWrapper>
            ) : (
              <ButtonWrapper>
                <PrimaryButton
                  size="large"
                  disabled={nextClicked}
                  onClick={handleNextClicked}
                  data-cy="next-button"
                >
                  {nextClicked ? "Getting Started" : "Get Started"}
                </PrimaryButton>
              </ButtonWrapper>
            )}
          </Preview>
        </>
      )}
      {permissionDenied && <PermissionDeniedHelp />}
    </>
  );
};

export default PreviewAV;
