import React from "react";
import Background from "../../../components/Background";
import styled from "styled-components";
import WhiteLogo from "../../logos/WhiteLogo";
import GradientCard from "../elements/cards/GradientCard";
import { PARAGRAPH_NORMAL, TITLE_HEADER } from "../../typography";
import { WHITE } from "../../colors";

const Wrapper = styled.div``;

const Content = styled(GradientCard)`
  position: absolute;
  float: left;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 500px;
  max-height: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: 45px 50px;
  ${PARAGRAPH_NORMAL}
  color: ${WHITE};
  ${(props) =>
    props.wide &&
    `
    max-width: 800px;
  `}
`;

const LogoWrapper = styled.div`
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Title = styled.div`
  ${TITLE_HEADER};
  text-align: center;
`;

const ChildrenWrapper = styled.div`
  margin-top: 15px;
  width: 100%;
  text-align: center;
`;

const ColumnWrapper = styled.div`
  flex: 1;
  margin: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const JoiningLayout = ({ wide, title, children, column2 }) => {
  return (
    <>
      <Background />
      <Wrapper>
        <Content wide={wide || Boolean(column2)}>
          <LogoWrapper>
            <WhiteLogo />
          </LogoWrapper>
          <ColumnWrapper>
            <Title>{title}</Title>
            {children && <ChildrenWrapper>{children}</ChildrenWrapper>}
          </ColumnWrapper>
          {column2 && <ColumnWrapper>{column2}</ColumnWrapper>}
        </Content>
      </Wrapper>
    </>
  );
};

export default JoiningLayout;
