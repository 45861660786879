import React from "react";
import JoiningLayout from "../../../components/layouts/JoiningLayout";
import PreviewNewSelfie from "../../../components/selfies/PreviewNewSelfie";
import styled from "styled-components";
import LargeMainButton from "../../../components/buttons/LargeMainButton";
import LargeLightOutlineButton from "../../../components/buttons/LargeLightOutlineButton";
import ButtonRow from "../../../components/buttons/ButtonRow";

const StyledButtonRow = styled(ButtonRow)`
  margin-top: 35px;
`;

const PreviewSelfie = ({
  video,
  selfieCanvas,
  onTakeSelfie,
  countingDown,
  onCountdownFinished,
  onCancel,
}) => {
  return (
    <JoiningLayout title={"Take a Selfie"}>
      <PreviewNewSelfie
        localVideo={video}
        selfieCanvas={selfieCanvas}
        countingDown={countingDown}
        onCountdownFinished={onCountdownFinished}
      />
      <p>Take a fun selfie for use throughout the game.</p>
      <StyledButtonRow>
        <LargeMainButton
          disabled={countingDown}
          noSpinner
          onClick={onTakeSelfie}
        >
          Take a Selfie
        </LargeMainButton>
        <LargeLightOutlineButton onClick={onCancel}>
          Back
        </LargeLightOutlineButton>
      </StyledButtonRow>
    </JoiningLayout>
  );
};

export default PreviewSelfie;
