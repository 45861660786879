import React, { useContext, useState } from "react";
import PageWrapper from "../components/PageWrapper";
import PreviewAV from "../components/PreviewAV";
import queryString from "query-string";
import PreviewSelfie from "../components/PreviewSelfie";
import { gql, useMutation } from "@apollo/client";
import { navigate } from "gatsby";
import FeatureFlagContext from "../contexts/FeatureFlagContext";
import { BrowserContextProvider } from "../contexts/BrowserContext";
import PreviewAVController from "../v2/controllers/join/PreviewAVController";
import { DeviceContextProvider } from "../contexts/DeviceContext";
import inTesting from "../util/inTesting";

const SAVE_SELFIE = gql`
  mutation SaveSelfie($selfie: String!) {
    saveSelfie(selfie: $selfie) {
      success
    }
  }
`;

export default function PreviewAVPage({ location }) {
  const { flags, flagsReady } = useContext(FeatureFlagContext);
  const { next, selfie } = queryString.parse(location.search);
  const [selfieData, setSelfieData] = useState();
  const [saveSelfieMutation] = useMutation(SAVE_SELFIE);

  if (!flagsReady && !inTesting()) {
    return null;
  }

  const v2 = flags["v2"];

  if (v2) {
    return (
      <BrowserContextProvider>
        <DeviceContextProvider>
          <PreviewAVController next={next} selfie={selfie} />
        </DeviceContextProvider>
      </BrowserContextProvider>
    );
  }

  const handleSelfieTaken = (data) => {
    setSelfieData(data);
  };

  const handleSave = async () => {
    await saveSelfieMutation({
      variables: {
        selfie: selfieData,
      },
    });
    if (next === "event") {
      await navigate("/event/");
    } else {
      await navigate("/play-quiz/");
    }
  };

  const handleRetake = () => setSelfieData(null);

  return (
    <PageWrapper>
      {!selfieData && (
        <PreviewAV
          next={next}
          selfie={selfie}
          onSelfieTaken={handleSelfieTaken}
        />
      )}
      {selfieData && (
        <PreviewSelfie
          data={selfieData}
          onRetake={handleRetake}
          onSave={handleSave}
        />
      )}
    </PageWrapper>
  );
}
