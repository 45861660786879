import React from "react";
import JoiningLayout from "../../../components/layouts/JoiningLayout";
import VideoWithVolumeLevel from "../../../components/av/VideoWithVolumeLevel";
import styled from "styled-components";
import SelectInput from "../../../components/inputs/SelectInput";
import LargeMainButton from "../../../components/buttons/LargeMainButton";
import VideoIcon from "../../../icons/VideoIcon";
import MicIcon from "../../../icons/MicIcon";
import getLogger from "../../../../util/getLogger";

const logger = getLogger("Previewing");

const VideoWrapper = styled.div`
  width: 267px;
  height: 200px;
  display: flex;
  justify-content: center;
`;

const VideoVolumeWrapper = styled.div`
  height: 200px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
`;

const SelectWrapper = styled.div`
  margin-top: 10px;
`;

const ButtonWrapper = styled.div`
  margin-top: 20px;
`;

const StyledButton = styled(LargeMainButton)`
  width: 100%;
`;

const AVSettings = ({
  video,
  audioLevel,
  videoDeviceOptions,
  videoDeviceId,
  setVideoDeviceId,
  audioInputDeviceOptions,
  audioInputDeviceId,
  setAudioInputDeviceId,
  onAVSettingsComplete,
}) => {
  const selectedAudioInputOption = audioInputDeviceOptions.find(
    (option) => option.value === audioInputDeviceId
  );

  const selectedVideoOption = videoDeviceOptions.find(
    (option) => option.value === videoDeviceId
  );

  const handleVideoChange = (option) => {
    logger.debug("option", option);
    setVideoDeviceId(option.value);
  };

  const handleAudioChange = (option) => {
    logger.debug("option", option);
    setAudioInputDeviceId(option.value);
  };

  return (
    <JoiningLayout title="Preview Video and Audio">
      <VideoVolumeWrapper>
        <VideoWithVolumeLevel
          video={<VideoWrapper>{video}</VideoWrapper>}
          level={audioLevel}
        />
      </VideoVolumeWrapper>
      <SelectWrapper>
        <SelectInput
          IconComponent={VideoIcon}
          placeholder="Select video"
          value={selectedVideoOption}
          options={videoDeviceOptions}
          onChange={handleVideoChange}
        />
      </SelectWrapper>
      <SelectWrapper>
        <SelectInput
          IconComponent={MicIcon}
          placeholder="Select audio input"
          value={selectedAudioInputOption}
          options={audioInputDeviceOptions}
          onChange={handleAudioChange}
        />
      </SelectWrapper>
      <ButtonWrapper>
        <StyledButton onClick={onAVSettingsComplete}>Continue</StyledButton>
      </ButtonWrapper>
    </JoiningLayout>
  );
};

export default AVSettings;
