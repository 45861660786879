import { useCallback } from "react";
import useSaveSelfieMutation from "../../../hooks/mutations/useSaveSelfieMutation";

const useSaveSelfie = (selfieData) => {
  const [saveSelfieMutation] = useSaveSelfieMutation();

  return useCallback(async () => {
    await saveSelfieMutation({
      variables: {
        selfie: selfieData,
      },
    });
  }, [selfieData]);
};
export default useSaveSelfie;
