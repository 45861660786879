import React from "react";
import styled from "styled-components";
import chromeMacImage from "./permission-denied-chrome-mac-help.png";
import chromeWindowsImage from "./permission-denied-chrome-windows-help.png";
import JoiningLayout from "../../../components/layouts/JoiningLayout";
import MainButton from "../../../components/buttons/MainButton";
import ButtonRow from "../../../components/buttons/ButtonRow";
import { CHROME, MAC_OS, WINDOWS } from "../../../../util/browserOsConstants";

const HelpImage = styled.img`
  width: 300px;
  border-radius: 10px;
`;

const P = styled.p`
  text-align: left;
`;

const PermissionDeniedScreen = ({ browserName, browserOs, onRefresh }) => {
  let column2;
  if (browserName === CHROME && browserOs === MAC_OS) {
    column2 = <HelpImage src={chromeMacImage} />;
  }
  if (browserName === CHROME && browserOs === WINDOWS) {
    column2 = <HelpImage src={chromeWindowsImage} />;
  }

  return (
    <JoiningLayout title={"Audio/Video Permission Required"} column2={column2}>
      <P>Hedgehog Trivia requires access to your camera and microphone.</P>
      <P>
        To fix this, click on the padlock next to the URL and select Allow for
        both Camera and Microphone.
      </P>
      <P>Once you have done that, refresh your browser tab.</P>
      <ButtonRow>
        <MainButton onClick={onRefresh}>Refresh</MainButton>
      </ButtonRow>
    </JoiningLayout>
  );
};

export default PermissionDeniedScreen;
