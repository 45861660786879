import React from "react";
import styled from "styled-components";
import { DEBUG } from "../../flags";
import { ACCENT_1, LIGHT_GREY_2 } from "../../colors";

const Wrapper = styled.div`
  height: 100%;
  background-color: ${LIGHT_GREY_2};
  border-radius: 100px;
  width: 15px;
  position: relative;
`;

const Indicator = styled.div`
  position: absolute;
  height: ${({ level }) => level * 10}%;
  width: 15px;
  background-color: ${ACCENT_1};
  border-radius: 100px;
  bottom: 0;
  transition-property: height;
  transition-duration: 0.25s;
`;

export default ({ level }) => {
  return (
    <Wrapper>
      <Indicator level={level} />
      {DEBUG && `Level: ${level}`}
    </Wrapper>
  );
};
