import React from "react";
import JoiningLayout from "../../../components/layouts/JoiningLayout";
import Selfie from "../../../components/selfies/Selfie";
import styled from "styled-components";
import ButtonRow from "../../../components/buttons/ButtonRow";
import LargeMainButton from "../../../components/buttons/LargeMainButton";
import LargeLightOutlineButton from "../../../components/buttons/LargeLightOutlineButton";
import ReviewNewSelfie from "../../../components/selfies/ReviewNewSelfie";

const SelfieWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 25px;
`;

const StyledButtonRow = styled(ButtonRow)`
  margin-top: 35px;
`;

const ReviewSelfie = ({ src, onSaveSelfie, onRetakeSelfie }) => {
  return (
    <JoiningLayout title="Here is your selfie">
      <SelfieWrapper>
        <ReviewNewSelfie
          selfie={<Selfie reversed src={src} type={"review"} />}
        />
      </SelfieWrapper>
      <p>Love it? Then join the game! Otherwise, take another.</p>
      <StyledButtonRow>
        <LargeMainButton onClick={onSaveSelfie}>Join the Game</LargeMainButton>
        <LargeLightOutlineButton onClick={onRetakeSelfie}>
          Retake
        </LargeLightOutlineButton>
      </StyledButtonRow>
    </JoiningLayout>
  );
};

export default ReviewSelfie;
