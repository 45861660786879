import React from "react";
import JoiningLayout from "../../../components/layouts/JoiningLayout";

const WaitingForPermission = () => (
  <JoiningLayout title="Preview Video and Audio">
    Waiting for permission to access camera and microphone.
  </JoiningLayout>
);

export default WaitingForPermission;
