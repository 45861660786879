import React from "react";
import Button from "./buttons/Button";
import styled from "styled-components";
import DarkPageHeading from "./DarkPageHeading";
import PrimaryButton from "./buttons/PrimaryButton";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ImagePreview = styled.img`
  border-radius: 500px;
`;

const ButtonRowWrapper = styled.div`
  margin: 40px 0 80px;
  display: flex;
  justify-content: center;
  & > button {
    margin: 0 10px;
  }
`;

const PreviewSelfie = ({ data, onRetake, onSave }) => {
  const handleRetakeClick = () => onRetake();
  const handleSaveClick = () => onSave();

  return (
    <Wrapper>
      <DarkPageHeading>Your Selfie</DarkPageHeading>
      <ImagePreview src={data} />
      <ButtonRowWrapper>
        <PrimaryButton size="large" onClick={handleSaveClick}>
          Save
        </PrimaryButton>
        <Button size="large" onClick={handleRetakeClick}>
          Retake
        </Button>
      </ButtonRowWrapper>
    </Wrapper>
  );
};

export default PreviewSelfie;
