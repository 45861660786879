import React from "react";
import styled from "styled-components";
import {
  ELEMENT_LIGHT,
  ELEMENT_OFF_WHITE,
  TEXT_LIGHT,
  WHITE,
} from "../../colors";
import LargeOutlineButton from "./LargeOutlineButton";

const Wrapper = styled(LargeOutlineButton)`
  color: ${ELEMENT_OFF_WHITE};
  border-color: ${ELEMENT_OFF_WHITE};

  &:hover:enabled,
  &.hover {
    border-color: ${WHITE};
    color: ${WHITE};
  }

  ${({ disabled }) =>
    disabled &&
    `
    color: ${TEXT_LIGHT};
    border-color: ${ELEMENT_LIGHT};
  `}
`;

const LargeLightOutlineButton = ({
  spinnerColor = ELEMENT_OFF_WHITE,
  ...rest
}) => {
  return <Wrapper spinnerColor={spinnerColor} {...rest} />;
};

export default LargeLightOutlineButton;
