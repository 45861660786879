import React, { useContext, useEffect, useRef, useState } from "react";
import PreviewAVScreen, {
  PERMISSION_DENIED,
  AV_SETTINGS,
  REVIEW_SELFIE,
  WAITING_FOR_PERMISSION,
  PREVIEWING_SELFIE,
} from "../../../screens/join/PreviewAVScreen";
import BrowserContext from "../../../../contexts/BrowserContext";
import useHandleRefresh from "../../../hooks/useHandleRefresh";
import Video from "../../../components/av/Video";
import useAttachTrackToVideo from "../../../hooks/av/useAttachTrackToVideo";
import usePollAudioLevel from "../../../hooks/av/usePollAudioLevel";
import styled from "styled-components";
import useSaveSelfie from "./useSaveSelfie";
import useCallbackRef from "../../../hooks/useCallbackRef";
import { navigate } from "gatsby";
import useEnumerateDevices from "../../../hooks/av/useEnumerateDevices";
import useVideoDeviceId from "../../../hooks/av/useVideoDeviceId";
import useAudioDeviceId from "../../../hooks/av/useAudioDeviceId";
import useLocalVideoTrack from "../../../hooks/av/useLocalVideoTrack";
import useLocalAudioTrack from "../../../hooks/av/useLocalAudioTrack";
import getLogger from "../../../../util/getLogger";
import sleep from "../../../../util/sleep";
import PermissionDeniedScreen from "../../../screens/join/PermissionDeniedScreen";
import useTakeSelfie from "../../../hooks/av/useTakeSelfie";

const logger = getLogger("PreviewAVController");

const SelfieCanvas = styled.canvas`
  display: none;
`;

const PreviewAVController = ({ next }) => {
  const { browserName, osName } = useContext(BrowserContext);
  const [state, setState] = useState(WAITING_FOR_PERMISSION);
  const [videoEl, setVideoEl] = useState();
  const videoRef = useCallbackRef(setVideoEl);
  const selfieCanvasRef = useRef();
  const [selfieData, setSelfieData] = useState();
  const [countingDown, setCountingDown] = useState(false);

  useEffect(() => {
    logger.debug("state", state);
  }, [state]);

  const {
    audioDevices,
    videoDevices,
    initialized,
    enumerateDevices,
  } = useEnumerateDevices();

  const audioInputDeviceOptions = audioDevices.map((device) => ({
    value: device.deviceId,
    label: device.label,
  }));

  const videoDeviceOptions = videoDevices.map((device) => ({
    value: device.deviceId,
    label: device.label,
  }));

  const [audioDeviceId, { setAudioDeviceId }] = useAudioDeviceId(
    audioDevices,
    initialized
  );
  const [videoDeviceId, { setVideoDeviceId }] = useVideoDeviceId(
    videoDevices,
    initialized
  );

  const [
    audioTrack,
    { permissionDenied: audioPermissionDenied },
  ] = useLocalAudioTrack({
    deviceId: audioDeviceId,
    onCreate: enumerateDevices,
  });
  const [
    videoTrack,
    { permissionDenied: videoPermissionDenied },
  ] = useLocalVideoTrack({
    deviceId: videoDeviceId,
    onCreate: enumerateDevices,
  });

  useEffect(() => {
    logger.debug("audioTrack", audioTrack);
    logger.debug("videoTrack", videoTrack);
    if (audioTrack && videoTrack) {
      logger.debug("got both audio and video tracks");
      sleep(1000).then(() => setState(AV_SETTINGS));
    }
  }, [audioTrack, videoTrack]);

  useEffect(() => {
    if (audioPermissionDenied || videoPermissionDenied) {
      sleep(1000).then(() => setState(PERMISSION_DENIED));
    }
  }, [audioPermissionDenied, videoPermissionDenied]);

  const audioLevel = usePollAudioLevel(audioTrack);
  useAttachTrackToVideo(videoTrack, videoEl);

  const handleAVSettingsComplete = () => {
    setState(PREVIEWING_SELFIE);
    setCountingDown(false);
  };

  const handleTakeSelfie = (data) => {
    setSelfieData(data);
    setState(REVIEW_SELFIE);
  };

  const takeSelfie = useTakeSelfie(videoEl, selfieCanvasRef, handleTakeSelfie);

  const handleCountdownFinished = async () => {
    await takeSelfie();
    setState(REVIEW_SELFIE);
  };

  const handleRetakeSelfie = () => {
    setCountingDown(false);
    setState(PREVIEWING_SELFIE);
  };

  const saveSelfie = useSaveSelfie(selfieData);

  const handleSaveSelfie = async () => {
    await saveSelfie();
    if (next === "event") {
      await navigate("/event/");
    } else {
      await navigate("/play-quiz/");
    }
  };

  const handleRefresh = useHandleRefresh();

  if (state === PERMISSION_DENIED) {
    return (
      <PermissionDeniedScreen
        browserName={browserName}
        browserOs={osName}
        onRefresh={handleRefresh}
      />
    );
  }

  return (
    <PreviewAVScreen
      state={state}
      browserName={browserName}
      onRefresh={handleRefresh}
      browserOs={osName}
      audioLevel={audioLevel}
      videoDeviceOptions={videoDeviceOptions}
      videoDeviceId={videoDeviceId}
      setVideoDeviceId={setVideoDeviceId}
      audioInputDeviceOptions={audioInputDeviceOptions}
      audioInputDeviceId={audioDeviceId}
      setAudioInputDeviceId={setAudioDeviceId}
      video={<Video videoRef={videoRef} reversed />}
      onAVSettingsComplete={handleAVSettingsComplete}
      selfieCanvas={<SelfieCanvas ref={selfieCanvasRef} />}
      onTakeSelfie={() => setCountingDown(true)}
      onBackToSettings={() => setState(AV_SETTINGS)}
      countingDown={countingDown}
      onCountdownFinished={handleCountdownFinished}
      onRetakeSelfie={handleRetakeSelfie}
      onSaveSelfie={handleSaveSelfie}
      selfieSrc={selfieData}
    />
  );
};

export default PreviewAVController;
